.projectItem{
    height: 200px;
    /* border: 2px solid yellow; */
    transition: all 0.3s ease;
    position: relative;
}
.projectCard{
    position: absolute;
    height: 100%;
    width: 100%;
}
.projectCard img{
    width: 100%;
    height: 100%;
}
.projectItem:hover .projectInfo{
    opacity: 1;
}
.projectInfo{
    position: absolute;
    background: #333c;
    opacity: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease-in-out;
}
.projectText{
    position: relative;
    top:-25%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.projectButton{
    position: relative;
    bottom:-25%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.projectItem:hover .projectText{
    top:0;
    opacity: 1;
}
.projectItem:hover .projectButton{
    bottom:0;
    opacity: 1;
}