.homeContent{
    background: #252934;
    padding: 0;
}

.flex{
    /* align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center; */
    /* display: flex;
    justify-content: center;
    width: 100%;
    margin: auto; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* display:flex;
    flex-direction: column;
    justify-content: space-around; 
    align-content: space-around; */
    
}
.headerText{
    text-align: center;
    font-size: 1.2rem;
    color: var(--text-primary);
    justify-items: center;
    align-items: center;
}
.highlightText{
    color: var(--accent);
}
.btn{
    background: var(--accent);
    color: var(--text-primary);
    border: none;
    padding: 20px;
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: background 200ms ease-in-out;
}
.btn:hover{
    background: var(--accent-dark);
}
@media only screen and (max-width: 600px){
    .headerText{
        font-size: 1.2rem;
    }
}
@media only screen and (min-width: 600px){
    .headerText{
        font-size: 2.5rem;
    }
}