body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scroll-behavior: smooth; */
  /* transition: var(--accent) 1000ms linear; */
}
body *{
  box-sizing: border-box;
}

html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
  transition: all 400ms !important;
  transition-delay: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  background: black;
  height: 100vh;
  width: 100%;
  color: white;
  margin: 0;
  padding: 0;
  position: relative;
  scroll-snap-align: start end;
}
:root {
  font-size: 16px;
  font-family: 'Consolas';
  --text-primary: #ffffff;
  --text-secondary: #dddddd;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --accent: #0078d4;
  --accent-dark: #106ebe;
}

.App::-webkit-scrollbar{
  width: 0.25rem;
}

.App::-webkit-scrollbar-track{
  background: #fff;
}

.App::-webkit-scrollbar-thumb{
  background: var(--accent);
}

.fake-big{
  position:absolute;
  top:10rem;
  left:0;
  z-index:0;  
  color:rgba(255, 255, 255, 0.1);
}
.fake-big{
  top:0;
  left:0;
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:flex;
  align-items:flex-start;
  text-align:left;
  justify-content:flex-start;
}

@media only screen and (min-width:600px){
  .fake-big{
    font-size:10rem;
    left: 5rem;
  }
  section{
    padding: 5rem 1rem 5rem 6rem;
  }
}

@media only screen and (max-width:600px){
  .fake-big{
    font-size: 5rem;
    left:0;
  }
  section{
    padding: 3rem 1rem;
  }
}
