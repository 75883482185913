input[type=text], input[type=email] {
    background: transparent;
    width: 300px;
    height: 50px;
    border: none;
    border-bottom: 2px solid gray;
    color: white;
}
input[type=text]:focus, input[type=email]:focus {
    border-color: var(--accent);
}
textarea{
    width: 300px;
    background: transparent;
    box-sizing: border-box;
    resize: vertical;
    border:none;
    border-bottom: 2px solid gray;
    height: 50px;
    color: white;
}
textarea:focus{
    border-color: var(--accent);
}
.socialLink{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 15px 0;
}
.socialIcon{
    padding: 10px;
    text-decoration: none;
    color: var(--text-primary);
}
.socialIcon:hover{
    color: var(--text-secondary);
}
.socialIco{
    font-size: 2.5rem;
}